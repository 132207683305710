import {
  Pass,
  PassAuxiliaryFields,
  PassBack,
  PassBarcode,
  PassBody,
  PassBodyBottom,
  PassBodyTop,
  PassBodyTopFieldContainer,
  PassBodyTopStripImage,
  PassBodyTopStripImageContainer,
  PassContainer,
  PassHeader,
  PassHeaderField,
  PassHeaderLabel,
  PassHeaderValue,
  PassLogo,
  PassLogoContainer,
  PassLogoText,
  PassPrimaryField,
  PassPrimaryLabel,
  PassPrimaryValue,
  PassSecondaryField,
  PassSecondaryFields,
  PassSecondaryLabel,
  PassSecondaryValue,
  PassThumbnail,
  PassThumbnailContainer,
  PassHorizontalRule,
} from 'components/passes/pass';
import { Barcode, EventTicket as EventTicketType, EventTicketInputs, CreatedPass } from 'types';


export function EventTicket({ pass, createdPass } : { pass: EventTicketType | EventTicketInputs | undefined, createdPass?: CreatedPass | undefined | null}) {

  const logoImage = createdPass?.user_info?.image_values?.logo_image ? createdPass?.user_info?.image_values?.logo_image : pass?.logo_image;
  const thumbnailImage = createdPass?.user_info?.image_values?.thumbnail_image ? createdPass?.user_info?.image_values?.thumbnail_image : pass?.thumbnail_image;
  return (
    <PassContainer>
      <Pass backgroundColor={pass?.background_color} foregroundColor={pass?.foreground_color} backgroundImg={pass?.background_image}>
        <PassHeader>
          <PassLogoContainer>
            <PassLogo src={logoImage} />
          </PassLogoContainer>
          <PassLogoText>
            {pass?.logo_text}
          </PassLogoText>
          { pass?.header_fields?.map((field: any) => {
              return(
              <PassHeaderField key={field.key}>
                <PassHeaderLabel color={pass?.label_color}>
                  {field.label}
                </PassHeaderLabel>
                <PassHeaderValue>
                  {field.value}
                </PassHeaderValue>
              </PassHeaderField>
              )
            })
          }
        </PassHeader>
        <PassHorizontalRule labelColor={pass?.label_color} />        
        <PassBody>
          { (pass?.primary_field || pass?.thumbnail_image) && (
            <PassBodyTop>
              { pass?.strip_image && (
                <PassBodyTopStripImageContainer>
                  <PassBodyTopStripImage src={pass?.strip_image} />
                </PassBodyTopStripImageContainer>
              )}
              <PassBodyTopFieldContainer>
                { pass?.primary_field && (
                  <PassPrimaryField>
                    <PassPrimaryLabel color={pass?.label_color}>
                      {pass?.primary_field?.label}
                    </PassPrimaryLabel>
                    <PassPrimaryValue>
                      {pass?.primary_field?.value}
                    </PassPrimaryValue>
                  </PassPrimaryField>
                )}
                { pass?.secondary_fields && (
                  <PassSecondaryFields>
                  { pass?.secondary_fields?.map((field: any) => {
                    return(
                      <PassSecondaryField key={field.key}>
                        <PassSecondaryLabel color={pass?.label_color}>
                          {field.label}
                        </PassSecondaryLabel>
                        <PassSecondaryValue>
                          {field.value}
                        </PassSecondaryValue>
                      </PassSecondaryField>
                    )
                  }) }
                  </PassSecondaryFields>
                )}
              </PassBodyTopFieldContainer>
              { pass?.thumbnail_image && (
                <PassThumbnailContainer>
                  <PassThumbnail src={thumbnailImage} />
                </PassThumbnailContainer>
              )}
            </PassBodyTop>
          )}
          <PassBodyBottom>
            <PassAuxiliaryFields >
            </PassAuxiliaryFields>
          </PassBodyBottom>
        </PassBody>
        { !!pass?.barcodes?.length && pass?.barcodes?.length > 0 && (
          <PassBarcode barcodes={pass?.barcodes} createdPass={createdPass}  />
        )}
      </Pass>
    </PassContainer>
  )
}


EventTicket.Back = PassBack<EventTicketInputs>;