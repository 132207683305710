import dayjs from 'dayjs';
import { useCreateStoreCardMutation } from 'services/storeCards';
import { StoreCard } from '../storeCard';
import { StoreCardInputs } from 'types';
import { createTemplateComponent } from 'components/reusable/template';

const initialData = {
  owner: undefined,
  allow_anonymous: false,
  verbose_name: '',
  description: '',
  app_launch_url: null,
  associated_store_identifiers: null,
  max_distance: 999,
  background_color: "#ffffff",
  foreground_color: "#000000",
  label_color: "#000000",
  expiration_date: dayjs(),
  relevant_date: dayjs(),
  logo_text: '',
  header_fields: [],
  back_fields: [],
  secondary_fields: [],
  auxiliary_fields: [],
  ibeacons: [],
  locations: [],
  barcodes: [],
  primary_field: undefined,
  logo_image: undefined,
  icon_image: undefined,
  strip_image: undefined,
}


export const CreateStoreCardTemplate = createTemplateComponent<StoreCardInputs>({
  initialData: initialData,
  TemplateRenderer: StoreCard,
  i18nNamespace: "storeCard",
  successBaseUrl: "/backoffice/store-cards/",
  createTemplateHook: useCreateStoreCardMutation,
})