
import dayjs from 'dayjs';
import { useCreateCouponMutation } from 'services/coupons';
import { Coupon } from '../coupon';
import { CouponInputs } from 'types';
import { createTemplateComponent } from 'components/reusable/template';


const initialData = {
  owner: undefined,
  allow_anonymous: false,
  verbose_name: '',
  description: '',
  app_launch_url: null,
  associated_store_identifiers: null,
  max_distance: 999,
  background_color: "#ffffff",
  foreground_color: "#000000",
  label_color: "#000000",
  expiration_date: dayjs(),
  relevant_date: dayjs(),
  logo_text: '',
  header_fields: [],
  primary_field: null,
  secondary_fields: [],
  auxiliary_fields: [],
  back_fields: [],
  ibeacons: [],
  locations: [],
  barcodes: [],
  logo_image: undefined,
  icon_image: undefined,
  strip_image: undefined,
}

export const CreateCouponTemplate = createTemplateComponent<CouponInputs>({
  initialData: initialData,
  TemplateRenderer: Coupon,
  i18nNamespace: "coupon",
  successBaseUrl: "/backoffice/coupons/",
  createTemplateHook: useCreateCouponMutation,
})