import dayjs from 'dayjs';
import { useCreateGenericPassTemplateMutation } from 'services/genericPasses';
import { GenericPass } from 'components/passes/genericPass';
import { createTemplateComponent } from 'components/reusable/template';
import { GenericPassInputs } from 'types';


const initialGenericPassValue = {
  owner: undefined,
  allow_anonymous: false,
  verbose_name: '',
  description: '',
  app_launch_url: null,
  associated_store_identifiers: null,
  max_distance: 100,
  background_color: "#ffffff",
  foreground_color: "#000000",
  label_color: "#000000",
  expiration_date: dayjs(),
  relevant_date: dayjs(),
  logo_text: '',
  header_fields: [],
  back_fields: [],
  secondary_fields: [],
  auxiliary_fields: [],
  ibeacons: [],
  locations: [],
  barcodes: [],
  primary_field: null,
  logo_image: undefined,
  icon_image: undefined,
  thumbnail_image: undefined,
}


export const CreateGenericPassTemplate = createTemplateComponent<GenericPassInputs>({
  initialData: initialGenericPassValue,
  TemplateRenderer: GenericPass,
  successBaseUrl: '/passes',
  i18nNamespace: 'genericPass',
  createTemplateHook: useCreateGenericPassTemplateMutation,
});